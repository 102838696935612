import { ReactElement } from "react";
import style from "./style.module.scss";
import ScrollToTop from "@components/shared/scrollToTop";
export default function LawsuitsEligibility(): ReactElement {
    return (
        <div className={style.wrapper}>
            <div className={style.inner}>
                <div className="md:w-10/12">
                    <h2>Am I Eligible for a Roundup Lawsuit?</h2>
                    <p>
                        To qualify for Roundup compensation, you must have used
                        Roundup in 1990 or later and received a non-Hodgkin's
                        lymphoma diagnosis. The weed killer must have been used
                        for at least a year before diagnosis, and a diagnosis
                        was made at least three years after the first use.
                    </p>
                </div>
                <div className="">
                    <ScrollToTop
                        colors={{
                            bg: "#ff7d06",
                            text: "#fff",
                        }}
                        className="mx-auto rounded mt-10"
                    >
                        Get My Free Lawsuit Case Review
                    </ScrollToTop>
                </div>
            </div>
        </div>
    );
}
